import { useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";
import Link from "@mui/material/Link";
import CallReceivedIcon from "@mui/icons-material/CallReceived";
import PaidIcon from "@mui/icons-material/Paid";
import SellIcon from "@mui/icons-material/Sell";
import MicrowaveIcon from "@mui/icons-material/Microwave";
import InventoryIcon from "@mui/icons-material/Inventory";
import MopedIcon from "@mui/icons-material/Moped";
import ProductionQuantityLimitsIcon from "@mui/icons-material/ProductionQuantityLimits";
import CancelIcon from "@mui/icons-material/Cancel";
import MoneyOffIcon from "@mui/icons-material/MoneyOff";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDProgress from "components/MDProgress";

import HttpService from "services/http-service";

export default function Data() {
  const [orders, setOrders] = useState([]);

  const fetchData = async (filters) => {
    try {
      const response = await HttpService.get("/kitchen/orders", filters);

      setOrders(response.data.orders || []);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };

  const OrderID = ({ id, external_order_id }) => (
    <MDTypography variant="body1" fontWeight="medium">
      <Link
        href={`/orders/${id}/${external_order_id}`}
        to={`/orders/${id}/${external_order_id}`}
        underline="always"
      >
        {external_order_id}
      </Link>
    </MDTypography>
  );

  // Typechecking props for the OrderID
  OrderID.propTypes = {
    id: PropTypes.number,
    external_order_id: PropTypes.number,
  };

  const Progress = ({ completed, total }) => {
    const progress = Math.floor((completed / total) * 100);

    let color;

    if (progress === 100) {
      color = "success"; // Green when progress is 100%
    } else if (progress < 50) {
      color = "error"; // Red when progress is 0%
    } else {
      // Gradient color based on progress
      color = "info";
    }

    return (
      <MDBox display="flex" alignItems="center">
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {progress}%
        </MDTypography>
        <MDBox ml={0.5} width="9rem">
          <MDProgress
            value={progress}
            color={color}
            variant="gradient"
            label={false}
          />
        </MDBox>
      </MDBox>
    );
  };

  Progress.propTypes = {
    completed: PropTypes.number,
    total: PropTypes.number,
  };

  const DispatchTime = ({ date, time }) => {
    // Split date and time
    const [year, month, day] = date.split("-");
    const [hour, minute, second] = time.split(":");

    // Create a new Date object
    const deliveryDateTime = new Date(
      year,
      month - 1,
      day,
      hour,
      minute,
      second,
    );

    const options = {
      day: "numeric",
      month: "short",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };

    // Format the date and time
    const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
      deliveryDateTime,
    );

    return <span>{formattedDate}</span>;
  };

  DispatchTime.propTypes = {
    date: PropTypes.string,
    time: PropTypes.string,
  };

  const OrderStatus = ({ status }) => {
    const statusMappings = {
      1: { text: "Order Received", color: "info", icon: <CallReceivedIcon /> },
      2: { text: "Waiting for Payment", color: "warning", icon: <SellIcon /> },
      3: { text: "Payment Confirmed", color: "info", icon: <PaidIcon /> },
      4: {
        text: "Preparing Dishes",
        color: "primary",
        icon: <MicrowaveIcon />,
      },
      5: { text: "For Packaging", color: "warning", icon: <InventoryIcon /> },
      6: { text: "Waiting for Rider", color: "primary", icon: <MopedIcon /> },
      7: { text: "Out for Delivery", color: "warning", icon: <MopedIcon /> },
      8: { text: "Completed", color: "success", icon: <Icon>check</Icon> },
      9: { text: "Cancelled", color: "error", icon: <CancelIcon /> },
      10: {
        text: "On-Hold",
        color: "secondary",
        icon: <ProductionQuantityLimitsIcon />,
      },
      11: { text: "Refund", color: "error", icon: <MoneyOffIcon /> },
    };

    const {
      text: statusText,
      color: buttonColor,
      icon: statusIcon,
    } = statusMappings[status] || {
      text: "Completed",
      color: "success",
      icon: <Icon>check</Icon>,
    };

    return (
      <MDButton
        variant="outlined"
        color={buttonColor}
        startIcon={statusIcon}
        circular={true}
        size="small"
      >
        {statusText}
      </MDButton>
    );
  };

  OrderStatus.propTypes = {
    status: PropTypes.number,
    order_id: PropTypes.number,
  };

  const tableData = {
    columns: [
      {
        header: "order id",
        accessorKey: "id",
        align: "left",
        cell: ({ getValue }) => getValue(),
      },
      {
        header: "completion",
        accessorKey: "completion",
        align: "left",
        cell: ({ getValue }) => getValue(),
      },
      {
        header: "status",
        accessorKey: "status",
        align: "left",
        cell: ({ getValue }) => getValue(),
      },
      {
        header: "dispatch time",
        accessorKey: "dispatch_time",
        align: "left",
        cell: ({ getValue }) => getValue(),
      },
    ],
    rows: orders.map((order) => ({
      id: <OrderID external_order_id={order.external_order_id} id={order.id} />,
      completion: (
        <Progress
          completed={order.items_completed_count}
          total={order.items_count}
        />
      ),
      status: <OrderStatus status={order.status} />,
      dispatch_time: (
        <DispatchTime date={order.delivery_date} time={order.dispatch_time} />
      ),
    })),
  };

  return { ...tableData, fetchData };
}
