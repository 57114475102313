import { useState, useEffect } from "react";

import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDTypography from "components/MDTypography";

import User from "./components/User";
import UserRole from "./components/UserRole";

import HttpService from "services/http-service";

export default function Data({ onActionClicked }) {
  const [users, setUsers] = useState([]);

  const fetchUsers = async (filters) => {
    try {
      const response = await HttpService.get("/kitchen/users", filters);

      setUsers(response.data.users || []);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchUsers(); // Initially fetch all data
  }, []);

  const tableData = {
    columns: [
      {
        header: "user",
        accessorKey: "user",
        align: "left",
        cell: ({ getValue }) => getValue(),
      },
      {
        header: "email",
        accessorKey: "email",
        align: "left",
        cell: ({ getValue }) => getValue(),
      },
      {
        header: "role",
        accessorKey: "role",
        align: "left",
        cell: ({ getValue }) => getValue(),
      },
      {
        header: "action",
        accessorKey: "action",
        align: "center",
        cell: ({ getValue }) => getValue(),
      },
    ],
    rows: users.map((user) => ({
      user: <User name={user.name} status={user.status} />,
      email: <MDTypography variant="body2">{user.email}</MDTypography>,
      role: <UserRole role={user.user_type_id} />,
      action: (
        <Icon
          sx={{ cursor: "pointer", fontWeight: "bold" }}
          fontSize="small"
          onClick={(e) => onActionClicked(e, user.id)}
        >
          more_vert
        </Icon>
      ),
    })),
  };

  return { ...tableData, fetchUsers };
}
