import { useCallback, useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

import { NavLink } from "react-router-dom";

// @mui material components
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";

// Material Dashboard 2 React components
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

import HttpService from "services/http-service";

export default function Data({ onEditButtonClicked = () => {} }) {
  const [customers, setCustomers] = useState([]);

  const fetchCustomers = useCallback(async (filters) => {
    try {
      const response = await HttpService.get("/kitchen/customers", filters);

      setCustomers(response.data.customers || []);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  }, []);

  const modifyCustomer = (customerID, modifiedData) => {
    const numericCustomerId = parseInt(customerID, 10);

    const recordIndex = customers.findIndex(
      (record) => record.id === numericCustomerId,
    );

    const newRows = [...customers];
    newRows[recordIndex] = { ...newRows[recordIndex], ...modifiedData };

    setCustomers(newRows);
  };

  const CustomerID = ({ id }) => (
    <MDTypography variant="body1" fontWeight="medium">
      <Link
        href={`/customers/${id}`}
        to={`/customers/${id}`}
        underline="always"
      >
        {id}
      </Link>
    </MDTypography>
  );

  // Typechecking props for the OrderID
  CustomerID.propTypes = {
    id: PropTypes.number,
  };

  const tableData = {
    columns: [
      {
        header: "customer id",
        accessorKey: "id",
        align: "left",
        cell: ({ getValue }) => <CustomerID id={getValue()} />,
      },
      {
        header: "name",
        accessorKey: "name",
        align: "left",
        cell: ({ getValue }) => (
          <MDTypography variant="body2">{getValue()}</MDTypography>
        ),
      },
      {
        header: "contact number",
        accessorKey: "contact_number",
        align: "left",
        cell: ({ getValue }) => (
          <MDTypography variant="body2">{getValue()}</MDTypography>
        ),
      },
      {
        header: "email address",
        accessorKey: "email",
        align: "left",
        cell: ({ getValue }) => (
          <MDTypography variant="body2">{getValue()}</MDTypography>
        ),
      },
      {
        header: "action",
        accessorKey: "action",
        align: "center",
        cell: ({ getValue }) => getValue(),
      },
    ],
    rows: customers.map((customer) => ({
      id: customer.id,
      name: customer.name,
      contact_number: customer.contact_number,
      email: customer.email,
      action: (
        <>
          <MDBox color="text" px={2}>
            <Stack direction="row" spacing={2}>
              <MDButton
                size="small"
                variant="outlined"
                color="primary"
                component={NavLink}
                to={`/customers/${customer.id}/orders`}
              >
                Orders
              </MDButton>
              <MDButton
                size="small"
                variant="outlined"
                color="info"
                onClick={() => onEditButtonClicked(customer.id)}
              >
                Edit
              </MDButton>
            </Stack>
          </MDBox>
        </>
      ),
    })),
  };

  return { ...tableData, fetchCustomers, modifyCustomer };
}
