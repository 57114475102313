import { useMemo, useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @tanstack/react-table components
import {
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  flexRender,
} from "@tanstack/react-table";

// @mui material components
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDPagination from "components/MDPagination";

// Material Dashboard 2 React example components
import DataTableHeadCell from "components/Tables/DataTable/DataTableHeadCell";
import DataTableBodyCell from "components/Tables/DataTable/DataTableBodyCell";

const DataTable = ({
  showTotalEntries = true,
  pagination = { variant: "gradient", color: "info" },
  isSorted = true,
  noEndBorder = false,
  table,
}) => {
  const columns = useMemo(() => table.columns, [table]);
  const data = useMemo(() => table.rows, [table]);
  const [sorting, setSorting] = useState([]);

  const tableInstance = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    initialState: {
      pagination: {
        pageIndex: 0, //custom initial page index
        pageSize: 25, //custom default page size
      },
    },
    state: {
      sorting
    },
    enableSorting: true,
    autoResetPageIndex: false,
    enableSortingRemoval: false,
  });

  const RenderPagination = () => {
    const totalPages = tableInstance.getPageOptions().length;
    const currentPage = tableInstance.getState().pagination.pageIndex + 1;

    const createPaginationRange = () => {
      const range = [];
      const delta = 2; // Number of neighbors to show around the active page.

      // Always include the first page.
      range.push(1);

      if (currentPage > delta + 2) {
        // Include ellipsis after the first page.
        range.push("...");
      }

      // Include range of pages around the active page.
      for (
        let i = Math.max(2, currentPage - delta);
        i <= Math.min(totalPages - 1, currentPage + delta);
        i++
      ) {
        range.push(i);
      }

      if (currentPage < totalPages - delta - 1) {
        // Include ellipsis before the last page.
        range.push("...");
      }

      // Always include the last page.
      if (totalPages > 1) {
        range.push(totalPages);
      }

      return range;
    };

    const paginationRange = createPaginationRange();
    return paginationRange.map((option, index) => (
      <MDPagination
        item
        key={index}
        onClick={() =>
          typeof option === "number"
            ? tableInstance.setPageIndex(option - 1)
            : null
        }
        active={currentPage === option}
        disabled={option === "..."}
      >
        {option}
      </MDPagination>
    ));
  };

  const setSortedValue = (column) => {
    let sortedValue;

    if (isSorted && column.isSorted) {
      sortedValue = column.isSortedDesc ? "desc" : "asc";
    } else if (isSorted) {
      sortedValue = "none";
    } else {
      sortedValue = false;
    }

    return sortedValue;
  };

  // Setting the entries starting point
  const entriesStart =
    tableInstance.getState().pagination.pageIndex === 0
      ? tableInstance.getState().pagination.pageIndex + 1
      : tableInstance.getState().pagination.pageIndex *
          tableInstance.getState().pagination.pageSize +
        1;

  // Setting the entries ending point
  let entriesEnd;

  if (tableInstance.getState().pagination.pageIndex === 0) {
    entriesEnd = tableInstance.getState().pagination.pageSize;
  } else if (
    tableInstance.getState().pagination.pageIndex ===
    tableInstance.getPageOptions().length - 1
  ) {
    entriesEnd = tableInstance.getRowCount();
  } else {
    entriesEnd =
      tableInstance.getState().pagination.pageSize *
      (tableInstance.getState().pagination.pageIndex + 1);
  }

  return (
    <TableContainer sx={{ boxShadow: "none", minHeight: "60vh" }}>
      {tableInstance.getRowCount() === 0 ? (
        <MDBox
          p={3}
          textAlign="center"
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <MDTypography variant="body1" color="dark">
            No records available.
          </MDTypography>
        </MDBox>
      ) : (
        <>
          <Table>
            <MDBox component="thead">
              {tableInstance
                .getHeaderGroups()
                .map((headerGroup, headerGroupKey) => (
                  <TableRow key={headerGroupKey}>
                    {headerGroup.headers.map((header, headerKey) => {
                      const headCellProps = {
                        width: header.width ? header.width : "auto",
                        align: header.align ? header.align : "left",
                        sorted: setSortedValue(header),
                      };
                      return (
                        <DataTableHeadCell
                          {...headCellProps}
                          canSort={header.column.getCanSort() && header.column.getSortIndex() !== -1}
                          onClick={header.column.getToggleSortingHandler()}
                          sorted={header.column.getNextSortingOrder()}
                          key={headerKey}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                        </DataTableHeadCell>
                      );
                    })}
                  </TableRow>
                ))}
            </MDBox>
            <TableBody>
              {tableInstance.getRowModel().rows.map((row, key) => {
                return (
                  <TableRow key={key}>
                    {row.getVisibleCells().map((cell) => {
                      let cellProps = {
                        noBorder:
                          noEndBorder &&
                          tableInstance.getRowCount() - 1 === key,
                        align: cell.column.align ? cell.column.align : "left",
                      };
                      return (
                        <DataTableBodyCell {...cellProps} key={cell.id}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext(),
                          )}
                        </DataTableBodyCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <MDBox
            display="flex"
            flexDirection={{ xs: "column", sm: "row" }}
            justifyContent="space-between"
            alignItems={{ xs: "flex-start", sm: "center" }}
            p={
              !showTotalEntries && tableInstance.getPageOptions().length === 1
                ? 0
                : 3
            }
          >
            {showTotalEntries && (
              <MDBox mb={{ xs: 3, sm: 0 }}>
                <MDTypography
                  variant="button"
                  color="secondary"
                  fontWeight="regular"
                >
                  Showing {entriesStart} - {entriesEnd} of{" "}
                  {tableInstance.getRowCount()} results
                </MDTypography>
              </MDBox>
            )}
            {tableInstance.getPageOptions().length > 1 && (
              <MDPagination
                variant={pagination.variant ? pagination.variant : "gradient"}
                color={pagination.color ? pagination.color : "info"}
              >
                {tableInstance.getCanPreviousPage() && (
                  <MDPagination
                    item
                    onClick={() => tableInstance.previousPage()}
                  >
                    <Icon sx={{ fontWeight: "bold" }}>chevron_left</Icon>
                  </MDPagination>
                )}
                <RenderPagination />
                {tableInstance.getCanNextPage() && (
                  <MDPagination item onClick={() => tableInstance.nextPage()}>
                    <Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>
                  </MDPagination>
                )}
              </MDPagination>
            )}
          </MDBox>
        </>
      )}
    </TableContainer>
  );
};

// Typechecking props for the DataTable
DataTable.propTypes = {
  entriesPerPage: PropTypes.oneOfType([
    PropTypes.shape({
      defaultValue: PropTypes.number,
      entries: PropTypes.arrayOf(PropTypes.number),
    }),
    PropTypes.bool,
  ]),
  showTotalEntries: PropTypes.bool,
  table: PropTypes.objectOf(PropTypes.array).isRequired,
  pagination: PropTypes.shape({
    variant: PropTypes.oneOf(["contained", "gradient"]),
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
    ]),
  }),
  isSorted: PropTypes.bool,
  noEndBorder: PropTypes.bool,
  dataTablePageIndex: PropTypes.number,
  onPageChange: PropTypes.func,
};

DataTable.displayName = "DataTable";

export default DataTable;
