import { useState } from "react";

// @mui material components
import Icon from "@mui/material/Icon";

import RiderStatus from "./components/RiderStatus";

import HttpService from "services/http-service";

export default function Data({ onActionClicked = () => {} }) {
  const [riders, setRiders] = useState([]);

  const fetchRiders = async () => {
    try {
      const response = await HttpService.get("/kitchen/riders");

      setRiders(response.data.riders || []);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };

  const addRider = (newRider) => {
    setRiders([...riders, newRider]);
  };

  const modifyRider = (riderId, modifiedData) => {
    const numericRiderID = parseInt(riderId, 10);

    const recordIndex = riders.findIndex(
      (record) => record.id === numericRiderID,
    );

    const newRows = [...riders];
    newRows[recordIndex] = { ...newRows[recordIndex], ...modifiedData };
    setRiders(newRows);
  };

  const deleteRider = (riderID) => {
    const numericRiderID = parseInt(riderID, 10);

    const updatedRows = riders.filter((record) => record.id !== numericRiderID);

    setRiders(updatedRows);
  };

  const tableData = {
    columns: [
      {
        header: "name",
        accessorKey: "name",
        align: "left",
        cell: ({ getValue }) => getValue(),
      },
      {
        header: "email",
        accessorKey: "email",
        align: "left",
        cell: ({ getValue }) => getValue(),
      },
      {
        header: "contact number",
        accessorKey: "contact_number",
        align: "left",
        cell: ({ getValue }) => getValue(),
      },
      {
        header: "status",
        accessorKey: "status",
        align: "left",
        cell: ({ getValue }) => getValue(),
      },
      {
        header: "assigned orders",
        accessorKey: "assigned_orders",
        align: "center",
        cell: ({ getValue }) => getValue(),
      },
      {
        header: "action",
        accessorKey: "action",
        align: "center",
        cell: ({ getValue }) => getValue(),
      },
    ],
    rows: riders.map((rider) => ({
      name: rider.name,
      email: rider.email,
      contact_number: rider.contact_number,
      status: <RiderStatus status={rider.status} />,
      assigned_orders: 0,
      action: (
        <Icon
          sx={{ cursor: "pointer", fontWeight: "bold" }}
          fontSize="small"
          onClick={(e) => onActionClicked(e, rider.id)}
        >
          more_vert
        </Icon>
      ),
    })),
  };

  return { ...tableData, fetchRiders, addRider, modifyRider, deleteRider };
}
